
import { defineComponent, onMounted, computed, ref, reactive } from 'vue';
import ModalMotor from './ModalMotor.vue';
// import InputRupiah from './InputRupiah.vue';
import { Http } from '@/Http';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'FormComponent',
    components: {
        ModalMotor,
        // InputRupiah
    },
    setup() {
        const loading = ref<boolean>(false)
        const form = ref<boolean>(false);
        const store = useStore();

        const motors = computed(() => {
            return store.state.list_motors;
        })

        const banks = computed(() => {
            return store.state.banks;
        })

        const router = useRouter();

        onMounted(() => {
            store.dispatch('getListMotor')
            store.dispatch('getBanks')
            // console.log(store.state.list_motors);
        })

        const file_kk = ref('');
        const file_ktp = ref('');
        const file_ktp_istri = ref('');


        const imageUrlKk = ref<any>("");
        const imageUrlKtp = ref<any>("");
        const imageUrlKtpIstri = ref<any>("");

        const selectedKk = (e: any) => {
            imageUrlKk.value = URL.createObjectURL(e.target.files[0]);
            file_kk.value = e.target.files[0];
        }

        const selectedKtp = (event: any) => {
            imageUrlKtp.value = URL.createObjectURL(event.target.files[0]);
            file_ktp.value = event.target.files[0];
        }

        const selectedKtpIstri = (event: any) => {
            imageUrlKtpIstri.value = URL.createObjectURL(event.target.files[0]);
            file_ktp_istri.value = event.target.files[0];
        }

        const dataMotor = reactive({
            nik: "",
            nama_lengkap: "",
            alamat: "",
            motor: "",
            bank: "",
            otr: "",
            dp: "",
            penjamin: "",
            telepon:"",
            nik_istri: "",
            motor_id:""
        })



        const getValueMotor = (val: any) => {
            // console.log(val);
            dataMotor.motor_id = val.id;
            // console.log(dataMotor.motor_id)
            return dataMotor.motor = val.merk;
        }

        const headers = { 'Content-Type': 'multipart/form-data' };

        const sendDataCustomer = async (e: any) => {
            e.preventDefault();

            form.value = false;
            if (!form.value) {
                loading.value = true;

                const dataForm = new FormData();
                dataForm.append('nik', dataMotor.nik);
                dataForm.append('nik_istri', dataMotor.nik_istri);
                dataForm.append('nama_lengkap', dataMotor.nama_lengkap);
                dataForm.append('alamat', dataMotor.alamat);
                dataForm.append('motor', dataMotor.motor);
                dataForm.append('motor_id', dataMotor.motor_id);
                dataForm.append('penjamin', dataMotor.penjamin);
                dataForm.append('dp', dataMotor.dp);
                dataForm.append('otr', dataMotor.otr);
                dataForm.append('bank', dataMotor.bank);
                dataForm.append('user', store.state.user.id);
                dataForm.append('telepon', dataMotor.telepon);
                dataForm.append('kartu_keluarga', file_kk.value);
                dataForm.append('ktp', file_ktp.value);
                dataForm.append('ktp_istri_suami', file_ktp_istri.value);
                // console.log(dataForm.get('kartu_keluarga'));
                // console.log(file_ktp.value);
                await Http.post("/sales/kirim-pengajuan?key=1f09b7281d203fab784ee47a029a4265",
                    dataForm, { headers }
                ).then(response => {
                    if (response.data.code === 200) {
                        // loading.value = false;
                        console.log(loading.value);

                        alert(response.data.status);

                        router.push({ name: 'home' })
                    } else {
                        alert(response.data.status);
                        // dataMotor.nik = '';
                        // dataMotor.nik_istri = '';
                        // dataMotor.nama_lengkap = '';
                        // dataMotor.penjamin = '';
                        // dataMotor.alamat = '';
                        // dataMotor.motor = '';
                        // dataMotor.motor_id = '';
                        // dataMotor.dp = '';
                        // dataMotor.otr = '';
                        // dataMotor.bank = '';
                        loading.value = false;
                    }
                })
            }

        }

        return {
            motors,
            getValueMotor,
            dataMotor,
            sendDataCustomer,
            loading,
            file_kk,
            file_ktp,
            selectedKk,
            selectedKtp,
            selectedKtpIstri,
            imageUrlKk,
            imageUrlKtp,
            imageUrlKtpIstri,
            form,
            banks,
            file_ktp_istri
        }
    }
})
