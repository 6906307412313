import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RequestView from '../views/RequestView.vue'
import StateRequestView from '../views/StateRequestView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/request-customer',
    name: 'request-customer',
    component: RequestView
  },
  {
    path: '/state-request',
    name: 'state-request',
    component: StateRequestView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  //  console.log(to,from);
  const isAuthenticated = localStorage.getItem("authenticated");
  // console.log(isAuthenticated && isLogged);
  //  next();
  
   if(to.name !== "login" && !isAuthenticated) next({name:"login"});
   if(to.name === "login" && isAuthenticated) next({name:"home"});
   else next();
  });
  

export default router
