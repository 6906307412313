import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppBar = _resolveComponent("AppBar")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_AppBar, { title: "Form Pengajuan Kredit" }),
        _createVNode(_component_Form),
        _createVNode(_component_Footer)
      ]),
      _: 1
    })
  ]))
}