import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app_bar, {
      style: {"background":"#0D47A1","color":"#ffffff","border-radius":"0px 0px 40px 40px"},
      elevation: "10",
      height: "150"
    }, {
      prepend: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: "/home",
          style: {"text-decoration":"none"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { style: {"font-size":"xx-large","color":"#ffffff"} }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-arrow-left")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_title, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.title), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}