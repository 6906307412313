

import Hero from '@/components/Hero.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue';
// import BottomNav from '@/components/BottomNav.vue'; // @ is an alias to /src
import { defineComponent, onMounted, computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import store from '@/store';
import { Http } from '@/Http';


export default defineComponent({
    name: 'HomeView',
    components: {
        Hero,
        Footer,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const count_of_credit = ref([]);


        const getCount = async () => {
            await Http.get(
                "sales/count-cmo/" + store.state.user.id + "?key=1f09b7281d203fab784ee47a029a4265"
            ).then(response => {
                // console.log(response.data.data);
                count_of_credit.value = response.data.data;
                return count_of_credit;
            })
        }

        const user_type = computed(() => {
            return store.state.type_user;
        });

        const bank_user = computed(() => {
            return store.state.bank_user;
        })

        const username = computed(() => {
            return store.state.user.username.toUpperCase();
        })

        const name = computed(() => {
            return store.state.user.name.toUpperCase();
        })




        onMounted(() => {
            store.dispatch('getStateAsked');
            getCount();
            // console.log(count_of_credit.value)
            // console.log(store.state.user.id)

            // console.log(proses.value);
            // console.log(username.value == '');
            if (username.value == '') {
                localStorage.removeItem('authenticated');
                localStorage.setItem('session', 'false');
                router.push({ name: 'login' })
            }
        })

        // const count_asked = computed(() => {
        //     return store.state.count_asked;
        // })



        return {
            username,
            // count_asked,
            user_type,
            bank_user,
            count_of_credit,
            name
        }
    }
})
