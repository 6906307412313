
import {defineComponent, ref,computed} from 'vue';

export default defineComponent({
  name: 'ModalMotor',
  // data(){
  //   return{
  //     dialog :false,
  //   }

  // },
  props: {
    motors: {
      type: Array
    }

  },
 
  setup(props,{emit}){
    const dialog = ref<boolean>(false);
    const merk = ref<any>("");

    const list_motors = computed(()=>{
      return props.motors?.filter((motor : any)=>{
        // console.log(motor.merk)
        return motor.merk.includes(merk.value.toUpperCase())
      })
    })

    const sendValueToParent = (merk:any,id:number)=>{
      dialog.value = false;
      let motor = {
        merk : merk,
        id:id
      }
      // console.log(val);
      // console.log(valMerk.value);
      emit('sendValueMotor',motor);
    }

    return {
      dialog,
      merk,
      list_motors,
      sendValueToParent,
    }
  }

})
