
import Footer from '@/components/Footer.vue';
import AppBar from '@/components/AppBar.vue';
import ModalFile from '@/components/ModalFile.vue';
import ModalVerifikasi from '@/components/ModalVerifikasi.vue';
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
// import { getUser } from '@/Extras';

export default defineComponent({
    name: 'StateRequestView',
    components: {
        Footer,
        AppBar,
        ModalFile,
        ModalVerifikasi
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const nik = ref<any>("");
        const nama = ref<any>("");

        const user_type = computed(() => {
            return store.state.type_user;
        })

        const username = computed(() => {
            return store.state.user.username.toUpperCase();
        })
        onMounted(() => {
            // console.log(username.value == '');
            // console.log(store.state.state_motors);
            store.dispatch('getStateAsked');
            if (username.value == '') {
                localStorage.removeItem('authenticated');
                localStorage.setItem('session', 'false');
                router.push({ name: 'login' })
            }
        })

        const getStateMotors = computed(() => {
            // return store.state.state_motors.filter((value : any)=>{
            //     // console.log(motor.merk)
            //      return value.nik.includes(nik.value) ;
            // }).filter((value : any)=>{
            //     // console.log(motor.merk)
            //      return value.name.includes(nama.value.toUpperCase()) ;
            // });
            return store.state.state_motors.filter((value: any) => {
                return value.nik.includes(nik.value);
            }).filter((value: any) => {
                return value.name.toUpperCase().includes(nama.value.toUpperCase());
            });
        })
        const toggle = null;


        return {
            toggle,
            nik,
            nama,
            getStateMotors,
            user_type,
            // getUser
        }
    }
})
