import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text = _resolveComponent("v-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          height: "350",
          style: {"padding":"40px !important","text-align":"center","background":"url('https://static.vecteezy.com/system/resources/previews/000/401/848/original/background-wallpaper-with-polygons-in-gradient-colors-vector.jpg')"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_avatar, {
              size: "200",
              rounded: "0",
              style: {"margin-top":"-45px !important","margin-bottom":"-45px !important"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  thumbnail: "",
                  src: "https://abu-motor.com/images/abumotor.png"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text, { class: "text-bold-title" }, {
              default: _withCtx(() => [
                _createTextVNode(" ABU MOTOR ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_text, { class: "text-bold-subtitle" }, {
              default: _withCtx(() => [
                _createTextVNode(" KREDIT ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card, {
          style: {"border-radius":"70px 0px 0px 0px","padding-top":"30px","height":"100%","box-shadow":"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)","margin-top":"-60px"},
          "prepend-icon": "mdi-account-key-outline",
          title: "Autentikasi Aplikasi"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_v_alert, {
              density: "comfortable",
              type: "error",
              variant: "tonal",
              class: "mb-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Session Berakhir, Silahkan Login Kembali. ")
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.sessioned]
            ]),
            _createVNode(_component_v_form, {
              modelValue: _ctx.form,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event)),
              onSubmit: _withModifiers(_ctx.login, ["prevent"]),
              style: {"padding-bottom":"100px","margin-left":"2%","margin-right":"2%","padding-left":"10px","padding-right":"10px","padding-top":"20px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  type: "text",
                  readonly: _ctx.loading,
                  modelValue: _ctx.user.username,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.username) = $event)),
                  rules: [_ctx.required],
                  clearable: "",
                  class: "mb-4",
                  variant: "outlined",
                  label: "Username"
                }, null, 8, ["readonly", "modelValue", "rules"]),
                _createVNode(_component_v_text_field, {
                  class: "mb-4",
                  readonly: _ctx.loading,
                  rules: [_ctx.required],
                  modelValue: _ctx.user.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.password) = $event)),
                  clearable: "",
                  type: "password",
                  variant: "outlined",
                  label: "Password"
                }, null, 8, ["readonly", "rules", "modelValue"]),
                _withDirectives(_createVNode(_component_v_alert, {
                  density: "comfortable",
                  type: "error",
                  variant: "tonal",
                  class: "mb-3"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.msg), 1)
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.logged]
                ]),
                _createVNode(_component_v_btn, {
                  color: "info",
                  class: "mr-4",
                  loading: _ctx.loading,
                  disabled: !_ctx.form,
                  type: "submit",
                  block: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Login ")
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"])
              ]),
              _: 1
            }, 8, ["modelValue", "onSubmit"]),
            _createVNode(_component_FooterComponent)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}