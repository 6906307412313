import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_modal_motor = _resolveComponent("modal-motor")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, { class: "mt-mobile" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      modelValue: _ctx.form,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form) = $event)),
                      onSubmit: _withModifiers(_ctx.sendDataCustomer, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          readonly: _ctx.loading,
                          type: "number",
                          modelValue: _ctx.dataMotor.nik,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataMotor.nik) = $event)),
                          clearable: "",
                          class: "mb-4",
                          variant: "outlined",
                          label: "NIK (No. KTP)"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          class: "mb-4",
                          readonly: _ctx.loading,
                          clearable: "",
                          type: "text",
                          modelValue: _ctx.dataMotor.alamat,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataMotor.alamat) = $event)),
                          variant: "outlined",
                          label: "Alamat"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          class: "mb-4",
                          readonly: _ctx.loading,
                          clearable: "",
                          type: "text",
                          variant: "outlined",
                          modelValue: _ctx.dataMotor.nama_lengkap,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataMotor.nama_lengkap) = $event)),
                          label: "Nama Lengkap"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          class: "mb-4",
                          readonly: _ctx.loading,
                          clearable: "",
                          type: "text",
                          variant: "outlined",
                          modelValue: _ctx.dataMotor.penjamin,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataMotor.penjamin) = $event)),
                          label: "Nama Penjamin/Istri"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          readonly: _ctx.loading,
                          type: "number",
                          modelValue: _ctx.dataMotor.nik_istri,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataMotor.nik_istri) = $event)),
                          clearable: "",
                          class: "mb-4",
                          variant: "outlined",
                          label: "NIK Istri (No. KTP)"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          class: "mb-4",
                          readonly: _ctx.loading,
                          clearable: "",
                          type: "text",
                          variant: "outlined",
                          modelValue: _ctx.dataMotor.telepon,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataMotor.telepon) = $event)),
                          label: "No Telepon"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_select, {
                          items: _ctx.banks,
                          itemTitle: "name",
                          itemValue: "id",
                          modelValue: _ctx.dataMotor.bank,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataMotor.bank) = $event)),
                          variant: "outlined",
                          label: "Pilihan Bank"
                        }, null, 8, ["items", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          class: "mb-4",
                          readonly: _ctx.loading,
                          modelValue: _ctx.dataMotor.dp,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataMotor.dp) = $event)),
                          clearable: "",
                          type: "number",
                          variant: "outlined",
                          label: "DP"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          class: "mb-4",
                          readonly: _ctx.loading,
                          modelValue: _ctx.dataMotor.otr,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dataMotor.otr) = $event)),
                          clearable: "",
                          type: "number",
                          variant: "outlined",
                          label: "OTR"
                        }, null, 8, ["readonly", "modelValue"]),
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.dataMotor.motor,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataMotor.motor) = $event)),
                          class: "mb-4",
                          clearable: "",
                          type: "text",
                          variant: "outlined",
                          label: "Pengajuan Motor",
                          readonly: ""
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_modal_motor, {
                          motors: _ctx.motors,
                          onSendValueMotor: _ctx.getValueMotor
                        }, null, 8, ["motors", "onSendValueMotor"]),
                        _createVNode(_component_v_file_input, {
                          label: "File KTP",
                          variant: "outlined",
                          onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.selectedKtp($event)))
                        }),
                        _withDirectives(_createElementVNode("img", {
                          src: _ctx.imageUrlKtp,
                          alt: "",
                          width: "200"
                        }, null, 8, _hoisted_1), [
                          [_vShow, _ctx.imageUrlKtp]
                        ]),
                        _createVNode(_component_v_file_input, {
                          label: "File KTP Istri",
                          variant: "outlined",
                          onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.selectedKtpIstri($event)))
                        }),
                        _withDirectives(_createElementVNode("img", {
                          src: _ctx.imageUrlKtpIstri,
                          alt: "",
                          width: "200"
                        }, null, 8, _hoisted_2), [
                          [_vShow, _ctx.imageUrlKtpIstri]
                        ]),
                        _createVNode(_component_v_file_input, {
                          label: "File KK",
                          variant: "outlined",
                          onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.selectedKk($event)))
                        }),
                        _withDirectives(_createElementVNode("img", {
                          src: _ctx.imageUrlKk,
                          alt: "",
                          width: "200"
                        }, null, 8, _hoisted_3), [
                          [_vShow, _ctx.imageUrlKk]
                        ]),
                        _createVNode(_component_v_btn, {
                          color: "success",
                          class: "mr-4",
                          loading: _ctx.loading,
                          type: "submit",
                          block: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Kirim "),
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-send")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["loading"])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onSubmit"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}