import { Http } from "./Http";

export const base_url = 'https://abu-motor.com';
// export const base_url = "http://abu-web.test";

export const getUser = async (val: number) => {
  await Http.get("/sales/cari-user/" + val).then((response) => {
    console.log(response.data.data);
    return response.data.data.name;
  });
};
