
import { defineComponent, ref, computed } from 'vue';
import { Http } from '@/Http';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { base_url } from '@/Extras';
import store from '@/store';

export default defineComponent({
    name: 'ModalVerifikasi',
    // data(){
    //   return{
    //     dialog :false,
    //   }

    // },
    props: {
        items: {
            type: Array
        },
        user_type: {
            type: String,
        },
        status:{
            type:String
        }

    },

    setup() {
        const dialog = ref<boolean>(false);
        const loadingApprove = ref<boolean>(false);
        const loadingRefuse = ref<boolean>(false);
        const store = useStore();

        const headers = { 'Content-Type': 'multipart/form-data' };

        const approve = async (val:any) => {
            loadingApprove.value = true;

            // let id_ask_credit = props.items?.id;
            // console.log(id_ask_credit)
            const dataForm = new FormData();
            dataForm.append('status', '1');
            // await Http.post().then();
            await Http.post('/sales/update-ajuan/'+val+'?key=1f09b7281d203fab784ee47a029a4265',dataForm,{headers}).then(response =>{
                if(response.data.status == true){
                    alert(response.data.keterangan);
                    dialog.value = false;
                    loadingApprove.value = false;
                    store.dispatch('getStateAsked');
                }
            })

        }
        const refuse = async (val:any) => {
            loadingRefuse.value = true;
            console.log(val);

            // let id_ask_credit = props.items?.id;
            // console.log(id_ask_credit)
            const dataForm = new FormData();
            dataForm.append('status', '2');
            // await Http.post().then();
            await Http.post('/sales/update-ajuan/'+val+'?key=1f09b7281d203fab784ee47a029a4265',dataForm,{headers}).then(response =>{
                // console.log(response.data.status);
                if(response.data.status == true){
                    alert(response.data.keterangan);
                    dialog.value = false;
                    loadingRefuse.value = false;
                    store.dispatch('getStateAsked');
                }
            })

        }

        const url = base_url;

        

        return {
            dialog,
            loadingApprove,
            loadingRefuse,
            approve,
            refuse,
            url,
        }
    }


})
