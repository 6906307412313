import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_ctx.user_type === 'sales')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_dialog, {
              modelValue: _ctx.dialog,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialog) = $event)),
              width: "800"
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({ color: _ctx.color }, props), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 2
                }, 1040, ["color"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, "Bukti File " + _toDisplayString(_ctx.title), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_img, {
                      src: _ctx.url+ _ctx.image,
                      cover: ""
                    }, null, 8, ["src"]),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "error",
                          variant: "flat",
                          block: "",
                          elevation: "10",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Tutup ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}