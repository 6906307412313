
import { defineComponent, ref } from 'vue';
import {base_url} from '../Extras';

export default defineComponent({
    name: 'ModalFile',
    props: {
        user_type: {
            type: String,
        },
        title: {
            type: String,
            default: 'File'
        },
        image: {
            type: String,
            default: 'Image File'
        },
        color: {
            type: String,
            default: 'success'
        }
        
    },
    setup() {
        const dialog = ref<boolean>(false);

        const url  = base_url;

        // const items = ref
        return {
            dialog,
            url
        }
    }
})
