
import { defineComponent, reactive, ref, onMounted } from 'vue';
import FooterComponent from "@/components/Footer.vue";
import { Http } from '@/Http';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
// import axios from 'axios';

export default defineComponent({
    name: 'LoginView',
    components: {
        // HelloWorld,
        FooterComponent
    },
    setup() {
        const logged = ref<boolean>(false);
        const user = reactive({
            username: "",
            password: ""
        })
        const loading = ref<boolean>(false);
        const form = ref<boolean>(false);
        const required = (v: boolean) => {
            return !!v || 'Harus Diisi !'
        };
        const sessioned = ref<boolean>(false);

        const store = useStore();
        const router = useRouter();

        const msg = ref<string>("");

        const login = async () => {
            form.value = false;

            if (!form.value) {
                loading.value = true;
                // console.log(form.value);
                await Http.post(
                    "/login-kredit?key=1f09b7281d203fab784ee47a029a4265",
                    user
                ).then(response => {
                    // console.log(response.data);
                    // console.log(user);
                    // loading.value = true
                    if (response.data.status === false) {
                        // store.commit('setFailedLogin', true);
                        logged.value = true
                        // alert('Maaf Password Salah');
                        // console.log(store.state.failedLogin);
                        // localStorage.setItem("session",'false');
                        msg.value = response.data.keterangan;
                        setTimeout(() => (loading.value = false, logged.value = false, form.value = true), 1000)
                        // setTimeout(() => (form.value = false), 1000)
                    }
                    if (response.data.status === true) {
                        // console.log(response.data);
                        const data = response.data.data;
                        // console.log(data);
                        // localStorage.setItem("authenticated", "true");
                        // store.commit('setFailedLogin', false);
                        // store.commit('setLogged', true);
                        store.commit('setUsername', data.username);
                        store.commit('setUserId', data.id);
                        store.commit('setTypeUser',data.tipe_pengguna);
                        store.commit('setUserBank',data.bank_cmo);
                        store.commit('setName',data.name);
                        // store.commit('setName', data.name);
                        // store.commit('setUserBranch', data.kantor_cabang);
                        // console.log(store.state.user.username);
                        if (store.state.user.username.length > 0 && response.data.status == true) {
                            localStorage.setItem('authenticated', 'true')
                            localStorage.setItem('session', 'true')
                        }

                        router.push({ name: 'home' })
                        // const redirectPage = () => {
                        //     // console.log(router);
                        //     router.push('/home');
                        // };

                    }


                });
            }
        }

        onMounted(() => {
            
            if (localStorage.getItem('session') == 'false') {
                // sessioned.value = true
                setTimeout(() => ( sessioned.value = true), 1000)
            } else {
                sessioned.value = false
            }
        })

        return {
            logged,
            user,
            loading,
            required,
            form,
            login,
            sessioned,
            msg
        }
    }

})
