
import {useRouter} from 'vue-router';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "HeroComponent",
    setup(){

        const router = useRouter();
        const logout = ()  => {
             localStorage.removeItem('authenticated');
             router.push('login');
             const redirectPage = ()=>{
                router.push('/');
             }
        }

        return {
            logout
        }
    }
})
