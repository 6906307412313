import { createStore } from 'vuex'
import { Http } from '@/Http'


export default createStore({
  state: {
    user:{ 
      name:"",
      username:"",
      branch_id:"",
      id:"",
    },
    list_motors : [],
    state_motors :[],
    count_asked:"",
    type_user:"",
    bank_user:"",
    banks :[],
    countTerima :"",
    countTolak :"",
    countProses :"",
    countTerimaOwner:"",
    countTolakOwner:"",
    countProsesOwner:"",
  },
  getters: {
  },
  mutations: {
    setUsername(state,value){
      state.user.username = value
    },
    setName(state,value){
      state.user.name = value
    },
    setUserId(state,value){
      state.user.id = value
    },
    setMotorList(state,value){
      state.list_motors = value
    },
    setStateMotors(state,value){
      state.state_motors = value
    },
    setCountAsked(state,value){
      state.count_asked = value
    },
    setBanks(state,value){
      state.banks = value
    },
    setTypeUser(state,value){
      state.type_user = value
    },
    setUserBank(state,value){
      state.bank_user = value
    },
    setTerimaCmo(state,value){
      state.countTerima = value
    },
    setTolakCmo(state,value){
      state.countTolak = value
    },
    setProsesCmo(state,value){
      state.countProses = value
    },
    setTerimaOwner(state,value){
      state.countTerimaOwner = value
    },
    setTolakOwner(state,value){
      state.countTolakOwner = value
    },
    setProsesOwner(state,value){
      state.countProsesOwner = value
    }
  },
  actions: {
    async getListMotor({commit}){
      await Http.get("/motor?key=1f09b7281d203fab784ee47a029a4265")
      .then(function(response){
        // console.log(response.data.data)
        commit('setMotorList',response.data.data); 
      })
    },

    async getStateAsked({commit}){
      await Http.get("/sales/status-pengajuan?key=1f09b7281d203fab784ee47a029a4265&id_user="+this.state.user.id)
      .then(function(response){
        // console.log(response.data.data);
        commit('setCountAsked',response.data.data.length);
        commit('setStateMotors',response.data.data);
      })
    },

    async getBanks({commit}){
      await Http.get("/sales/get-banks?key=1f09b7281d203fab784ee47a029a4265")
      .then(function(response){
        // console.log(response.data.data);
        commit('setBanks',response.data.data);
      })
    },
    
    async getCountCmo({commit}){
      await Http.get(
        "sales/count-cmo/"+this.state.user.id+"?key=1f09b7281d203fab784ee47a029a4265"
    ).then(response => {
        console.log(console.log(response.data.data));
        commit('setTerimaCmo',response.data.data.terima);
        commit('setTolakCmo',response.data.data.tolak);
        commit('setProsesCmo',response.data.data.proses);
        commit('setTerimaOwner',response.data.data.terima_owner);
        commit('setTolakOwner',response.data.data.tolak_owner);
        commit('setProsesOwner',response.data.data.proses_owner);
    });
    }
  },
  modules: {
  }
})
