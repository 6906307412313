
import Form from '@/components/Form.vue';
import AppBar from '@/components/AppBar.vue';
import Footer from '@/components/Footer.vue';
import { defineComponent,onMounted,computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
    name:'RequestView',
    components:{
        Form,
        AppBar,
        Footer
    },
    setup(){
        const store = useStore();
        const router = useRouter();
        const username = computed(()=>{
            return store.state.user.username.toUpperCase();
        })
        onMounted(()=>{
            // console.log(username.value == '');
            console.log(store.state.state_motors);
            if(username.value == ''){
                localStorage.removeItem('authenticated');
                localStorage.setItem('session','false');
                router.push({name:'login'})
            }
        })
    }
})
